import IntroJs from 'intro.js' // introjs库
import { useI18n } from 'vue-i18n'
import { includes } from 'lodash-es'
import 'intro.js/minified/introjs.min.css'
import { useReportStore } from '@/stores/modules/reports/index'

export default () => {
  const { t } = useI18n()
  const reportStore = useReportStore()
  function initPageIntro(steps: any, page: string) {
    nextTick(() => {
      if (!includes(reportStore.guidanceUnDisplayList, page)) {
        const curIntro = IntroJs()
        curIntro.setOptions({
          prevLabel: t('common.prev'),
          nextLabel: t('common.next'),
          skipLabel: t('common.skip'),
          doneLabel: t('common.done'),
          tooltipPosition: `bottom`, // 引导说明框相对高亮说明区域的位置
          hidePrev: true, // 隐藏第一步中的上一个按钮
          tooltipClass: `elv-intro-step`, // 引导说明文本框的样式
          highlightClass: `elv-intro-interaction`, // 说明高亮区域的样式
          exitOnOverlayClick: false, // 是否允许点击空白处退出
          showStepNumbers: true, // 是否显示说明的数据步骤
          keyboardNavigation: false, // 是否允许键盘来操作
          showButtons: true, // 是否按键来操作
          showBullets: false, // 是否显示导航圆点
          showProgress: false, // 是否显示进度条
          scrollToElement: true, // 是否滑动到高亮的区域
          overlayOpacity: 0, //  遮罩层的透明度
          positionPrecedence: [`bottom`, `top`, `right`, `left`] /* 当位置选择自动的时候，位置排列的优先级 */,
          disableInteraction: true, // 是否禁用与突出显示的框内的元素的交互
          hintPosition: 'top-middle',
          steps
        })
        curIntro.oncomplete(() => {
          reportStore.guidanceUnDisplayList.push(page)
          // 点击结束按钮后执行的事件
        })
        curIntro.onexit(() => {
          // 点击跳过按钮后执行的事件
          reportStore.guidanceUnDisplayList.push(page)
          console.log(`onexit`)
        })
        curIntro.onchange(() => {
          // 点击下一步执行的事件
          console.log(`onchange`)
        })
        curIntro.start()
      }
    })
  }
  return {
    initPageIntro
  }
}
